var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v("Default rating")]),_c('div',{staticClass:"text-center"},[_c('star-rating',{attrs:{"star-size":25}})],1)])]),_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v("Half rating")]),_c('star-rating',{staticClass:"rating",attrs:{"increment":0.5,"star-size":25}})],1)]),_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v(" Readonly rating with a value ")]),_c('star-rating',{attrs:{"rating":3,"read-only":true,"star-size":25}})],1)]),_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v("Customized rating")]),_c('star-rating',{attrs:{"rating":1,"active-color":"#ff3d60","star-size":25}})],1)]),_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v("Only fill selected")]),_c('star-rating',{attrs:{"star-size":25},on:{"rating-selected":_vm.setRating}})],1)]),_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v("Custom Shap")]),_c('star-rating',{attrs:{"star-size":25,"border-width":4,"border-color":"#d8d8d8","rounded-corners":true,"star-points":[
                    23,
                    2,
                    14,
                    17,
                    0,
                    19,
                    10,
                    34,
                    7,
                    50,
                    23,
                    43,
                    38,
                    50,
                    36,
                    34,
                    46,
                    19,
                    31,
                    17 ]}})],1)]),_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v("Fluid Stars")]),_c('star-rating',{attrs:{"increment":0.01,"fixed-points":2,"star-size":25}})],1)]),_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v("Bordered Star")]),_c('star-rating',{attrs:{"border-width":3,"star-size":25}})],1)]),_c('div',{staticClass:"col-xl-3 col-md-4 col-sm-6"},[_c('div',{staticClass:"p-4 text-center"},[_c('h5',{staticClass:"font-size-15 mb-3"},[_vm._v("RTL rating")]),_c('star-rating',{attrs:{"rtl":true,"increment":0.5,"star-size":25}})],1)])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }